import { render, staticRenderFns } from "./DesignInquiryDetail.vue?vue&type=template&id=ed3e1586&scoped=true&"
import script from "./DesignInquiryDetail.vue?vue&type=script&lang=js&"
export * from "./DesignInquiryDetail.vue?vue&type=script&lang=js&"
import style0 from "./DesignInquiryDetail.vue?vue&type=style&index=0&id=ed3e1586&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3e1586",
  null
  
)

export default component.exports